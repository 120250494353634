<script setup lang="ts"></script>

<template>
  <div>
    <LayoutCheckoutHeader />
    <LayoutNotifications />
    <main class="container mx-auto px-5 md:px-10">
      <slot />
    </main>
  </div>
</template>
